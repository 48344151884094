import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const AddingTestSuiteToTestCase = () => {
  return (
    <LayoutComponent>
      <h2>Adding a Test Suite to a Test Case</h2>
      <div className="testimonials">
        <h5>Note</h5>
        <p>
          A test case can be associated with a maximum of 3 test suites at a
          time. You can add a test suite while creating a test case or later
          while editing the test case.
        </p>
      </div>

      <h3>Steps to Add a Test Suite to a Test Case:</h3>
      <ol>
        <li>
          On the <strong>Test Cases</strong> main page, click{" "}
          <strong>+New Test Case</strong> to create a new test case.
        </li>
        <li>
          In <strong>Create Test Case</strong> modal, enter the details for your
          test case.
        </li>
        <li>
          Select the test suite(s) you want to associate your test case with.
        </li>
      </ol>
      <p>Or</p>
      <ol>
        <li>
          You can also add the test suite to an <strong>existing</strong> test
          case by editing the test case later.
        </li>
        <li>
          On <strong>Test Case Details</strong> page, select the test suite(s)
          you want to associate.
        </li>
      </ol>
    </LayoutComponent>
  )
}

export default AddingTestSuiteToTestCase
